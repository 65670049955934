<template>
  <div class="profile-page-settings">
    <Hero>
      <div class="hero-inner">
        <div v-if="selectedEvent" class="hero-inner__text">
          {{ selectedEvent.title }}
        </div>

        <!-- <c-img
          :style="{position: 'absolute', left: '-40px', bottom: '75px'}"
          src="assets/img/profile-user/bg-text.png"
          alt=""
        /> -->
      </div>
    </Hero>

    <v-container class="profile-container">
      <div class="profile">
        <v-form
          ref="form"
          class="profile__form"
          @submit.prevent="save"
        >
          <v-row>
            <v-col cols="12" lg="3">
              <ImageUploader
                :value="user.avatar.url || avatarPlug"
                :initials="initials"
                :rules="[fileSizeRule]"
                @change="onAvatarChange"
              />
            </v-col>

            <v-col cols="12" lg="9">
              <div class="profile__title">
                {{ $t('profile settings') }}
              </div>

              <div class="profile__form">
                <div class="profile__input">
                  <v-text-field
                    v-model="user.lastName"
                    class="text-field"
                    name="lname"
                    solo
                    :rules="[requiredRule, minLengthRule]"
                    :label="upperFirst($t('last name'))"
                    hide-details
                  />
                </div>

                <div class="profile__input">
                  <v-text-field
                    v-model="user.firstName"
                    class="text-field"
                    :rules="[requiredRule, minLengthRule]"
                    solo
                    :label="upperFirst($t('first name'))"
                    hide-details
                  />
                </div>
              </div>

              <div class="profile__actions">
                <v-btn
                  :height="60"
                  :disabled="isLoading"
                  class="profile__button profile__button--save button text-capitalize"
                  type="submit"
                  x-large
                >
                  {{ upperFirst($t('save')) }}
                </v-btn>

                <v-btn
                  :height="60"
                  class="profile__button button button_white text-capitalize"
                  x-large
                  @click.prevent="cancel"
                >
                  {{ upperFirst($t('cancel')) }}
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              lg="9"
              offset="0"
              offset-lg="3"
            >
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="user.customizedMaterials"
                class="profile__customizes-materials"
                v-html="user.customizedMaterials"
              />
              <!-- eslint-enable vue/no-v-html -->
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-container>

    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>

    <modal
      v-model="open"
      title="action not available"
      @onCloseModal="open = false"
    >
      <template slot="content">
        <div class="modal__content">
          {{ $t('broadcasting will be available soon') }}
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import './styles.scss'
import Modal from '@/components/Modal'
import businessEventService from '@/services/businessEvent'
import { defaultState } from '@/services/user/store'
import { cloneDeep, upperFirst } from 'lodash'
import ImageUploader from '@/components/ImageUploader'
import { requiredRule, emailRule } from '@/utils/validate'
import Hero from '@/components/Hero'
import { notifySuccess } from '@/utils/notify'
import store from './store'
import { mapActions, mapState } from 'vuex'
import EventBus from '@/EventBus'
import avatarPlug from '@/assets/img/theme/person-plug-mini.png'

export default {
  components: {
    ImageUploader,
    Hero,
    Modal
  },

  store,

  data: () => ({
    user: defaultState().user,
    selectedEvent: null,
    isLoading: false,
    open: false,
    avatarPlug
  }),

  computed: {
    ...mapState({
      initialUser: 'user'
    }),

    initials () {
      return `${this.user.firstName?.[0] ?? ''}${this.user.lastName?.[0] ?? ''}`
    }
  },

  async created () {
    EventBus.subscribe('language:update', this.langChanged)
    this.selectedEvent = await businessEventService.getActiveEvent()

    try {
      this.isLoading = true
      await this.getUser()
      this.user = cloneDeep(this.initialUser)
    } finally {
      this.isLoading = false
    }
  },

  beforeDestroy () {
    EventBus.unsubscribe('language:update', this.langChanged)
  },

  methods: {
    ...mapActions(['updateUser', 'getUser']),

    langChanged (lang) {
      this.user.language = lang.code
    },

    async save () {
      if (!this.$refs.form.validate()) return

      try {
        this.isLoading = true
        await this.updateUser(this.user)

        this.user = cloneDeep(this.initialUser)
        notifySuccess(this.$t('success'))
      } finally {
        this.isLoading = false
      }
    },

    cancel () {
      this.user = cloneDeep(this.initialUser)
      this.$refs.form.resetValidation()
    },

    onAvatarChange (blob) {
      this.user.avatar.id = null
      this.user.avatar.url = blob
      this.save()
    },

    requiredRule,
    emailRule,
    minLengthRule: val => !!val && val.length >= 3,

    fileSizeRule (value) {
      return !value || value.size < 4000000 || this.$t('errors.validate.FILE_SIZE_MAX', { max: 4 })
    },

    upperFirst
  },

  beforeRouteLeave (to, from, next) {
    if (!this.selectedEvent.isStarted && to.path !== '/auth') {
      this.open = true
    }
    next()
  }
}
</script>

<style lang="scss" scoped>
.profile__button--save {
  color: #fff;
  background: linear-gradient(45deg, var(--main-primary-background-color), var(--main-secondary-background-color));
}

.hero-inner__text {
  font-size: 300px;
  font-weight: 800;
  font-family: var(--main-primary-font);
  color: #fff;
  text-align: center;
  line-height: 1;
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
  text-transform: uppercase;
  user-select: none;
  -webkit-text-stroke: 2px rgba(#fff, 0.3);
  -webkit-text-fill-color: transparent;
}
</style>
